// import react, react-markdown-editor-lite, and a markdown parser you like
import React from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import withStyles from "@material-ui/styles/withStyles";
import withRoot from "../utils/withRoot";

const initialMarkdown = `
**abc**
`;

const styles = () => ({
    root: {
        fontWeight: "bold",
        // backgroundColor: '#000'
    },
});

// Register plugins if required
// MdEditor.use(YOUR_PLUGINS_HERE);

// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

function onImageUpload(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = data => {
            // console.log(data);
            resolve(data.target.result);
        };
        reader.readAsDataURL(file);
        // console.log(file);
    });
}

class EditPages extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        disabled: true,
        text: ''
      }
  
    //   var url = this.props.location.href
    //   if (typeof(url) === 'string') {
    //     if (url.indexOf('staging') !== -1) {
    //       // console.log(this.state.disabled)
    //       this.state.disabled = true
    //       // console.log(this.state.disabled)
    //     } else {
    //       this.state.seo = true
    //     }
    //     // console.log(this.state.seo)
    //   }
      this.handleEditorChange = this.handleEditorChange.bind(this);
      // authIP(this)

      var url = this.props.location.href
      if (typeof(url) === 'string') {
        if (url.indexOf('http://localhost:8000/') !== -1) {
            // console.log(this.state.disabled)
            this.state.disabled = false
            // console.log(this.state.disabled)
            // console.log(this.state.seo)
        }
      }
    }
  
    async componentDidMount() {
        // var elem = document.getElementById('inputMarkdown_md');
        // elem.innerText = '**aaa**'
        // console.log(elem);
        var res = await fetch('/api/authIP', {
            method: 'POST'
        }).then((response) => {
            // console.log(response)
            return response.text();
        });
        // console.log(res)
        if (res === 'Y') {
            // console.log(state.state.disabled)
            this.setState({disabled: false});
        }
        // console.log(this.disabled)
    }

    // Finish!
    handleEditorChange({ html, text }) {
        console.log('handleEditorChange', html, text);
        this.text = text;
        console.log(this.text)
    }

    render() {
        // const products = props.data.allMarkdownRemark.edges;
        const {disabled} = this.state;
        // console.log(disabled)
        // if (this.props.location.href.indexOf('staging') != -1) {
        //   disabled = true
        // }

        if (disabled) {
            // console.log(this.state.disabled)
            return (
                <h1>You can't enter in this pages.</h1>
            );
        } else {
            return (
                <MdEditor defaultValue={initialMarkdown} style={{ height: '500px' }} renderHTML={text => mdParser.render(text)} onImageUpload={onImageUpload} onChange={this.handleEditorChange}/>
            );
        }
    }
};

export default withRoot(withStyles(styles)(EditPages));